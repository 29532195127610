.App {
  font-family: 'Helvetica Neue', sans-serif;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: #1c1c1c;
  color: #fff;
}

.section-title {
  text-align: center;
  font-size: 3rem;
  margin: 2rem 0;
  letter-spacing: 0.2rem;
}

.product-item {
  margin-bottom: 3rem;
  padding: 3rem;
  border-radius: 1rem;
  background-color: #282828;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.product-item h2 {
  margin-top: 0;
  font-size: 2rem;
  letter-spacing: 0.2rem;
}

.product-item p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 1rem 0;
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-list li {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.contact-list li i {
  font-size: 2rem;
  margin-right: 1rem;
}
